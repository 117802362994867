<template>
  <div class="section" id="carousel">
    <div class="container">
      <div class="title">
        <h4>Photo Gallery</h4>
      </div>
      <div class="row justify-content-center">
        <div class="col-8">
          <el-carousel height="500px">
            <el-carousel-item>
              <img class="d-block" src="img/pippers_on_the_field.jpg" alt="Pippers On The Field" />
              <div class="carousel-caption d-none d-md-block">
                <h5>Pippers On The Field</h5>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <img class="d-block" src="img/vendorsrow.jpg" alt="Vendors Row" />
              <div class="carousel-caption d-none d-md-block">
                <h5>Vendor Row</h5>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <img class="d-block" src="img/pippers_collage.jpg" alt="Pippers Collage" />
              <div class="carousel-caption d-none d-md-block">
                <h5>Pippers Collage</h5>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, CarouselItem } from 'element-ui';

export default {
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  }
};
</script>
<style></style>
